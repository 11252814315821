import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Markdown from 'react-markdown';
import LayoutContainer from '../containers/layout';
import PageBackgroundImageComponent from '../components/page-background-image';
import PageContentComponent from '../components/page-content';
import PageTitleComponent from '../components/page-title';
import DividerComponent from '../components/divider';
import ContactUsContainer from '../containers/contact-us';
import ButtonBlackGoldComponent from '../components/button-black-gold';
import TranslationModesContainer from '../containers/translation-modes';

const TranslationsPage = () => {
  const data = useStaticQuery(query);

  return (
    <LayoutContainer title={data.strapiTranslationEnglish.title} description={data.strapiTranslationEnglish.description}>
      <PageTitleComponent showBackground={true}>
        <div className="text-4xl lg:text-5xl text-black">{data.strapiTranslationEnglish.title}</div>
      </PageTitleComponent>

      <PageBackgroundImageComponent image={data.strapiTranslationEnglish.backgroundImage.localFile.childImageSharp.gatsbyImageData} />

      <PageContentComponent showBackground={true}>
        <section className="mb-12">
          <Markdown className="no-widows w-full lg:w-3/5 markdown">{data.strapiTranslationEnglish.description}</Markdown>
        </section>

        <section className="mb-12">
          <DividerComponent />
        </section>

        <section className="mb-12">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-3/5 lg:pr-20">
              <Markdown className="no-widows w-full markdown mb-12">{data.strapiTranslationEnglish.modeDescription}</Markdown>
              <TranslationModesContainer translationModes={data.strapiTranslationEnglish.translation_modes} />
              <Markdown className="no-widows w-full markdown mt-12">{data.strapiTranslationEnglish.modeNotes}</Markdown>
            </div>
            <div className="relative flex flex-col justify-evenly w-full lg:w-2/5 lg:pl-20 pt-12 lg:pt-4">
              <Link to="/cennik" className="block">
                <ButtonBlackGoldComponent title="ZOBACZ" subtitle="Cennik" />
              </Link>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <DividerComponent />
        </section>

        <section className="mb-12">
          <Markdown className="w-full markdown mb-12">{data.strapiTranslationEnglish.stepDescription}</Markdown>
          <TranslationModesContainer translationModes={data.strapiTranslationEnglish.translation_steps} />
        </section>

        <section className="mb-12">
          <DividerComponent />
        </section>

        <section>
          <ContactUsContainer />
        </section>
      </PageContentComponent>
    </LayoutContainer>
  );
};

const query = graphql`
  query {
    strapiTranslationEnglish {
      title
      description
      modeDescription
      modeNotes
      stepDescription
      backgroundImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO, quality: 92)
          }
        }
      }
      translation_modes {
        title
        description
      }
      translation_steps {
        title
        description
      }
    }
  }
`;

export default TranslationsPage;
