import * as React from 'react';

const TranslationModesContainer = ({ translationModes }) => (
  <>
    <div
      className={`grid grid-cols-1 ${
        translationModes.length < 4 ? (translationModes.length < 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-3') : 'lg:grid-cols-4'
      } gap-8`}
    >
      {translationModes.map((el, i) => (
        <div key={i}>
          <div className="text-3xl text-blue-600 mb-2.5">{el.title}</div>
          <div>{el.description}</div>
        </div>
      ))}
    </div>
  </>
);

export default TranslationModesContainer;
